@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icons-benefits/icomoon.eot?tyk1x3');
  src:  url('/fonts/icons-benefits/icomoon.eot?tyk1x3#iefix') format('embedded-opentype'),
    url('/fonts/icons-benefits/icomoon.ttf?tyk1x3') format('truetype'),
    url('/fonts/icons-benefits/icomoon.woff?tyk1x3') format('woff'),
    url('/fonts/icons-benefits/icomoon.svg?tyk1x3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-moon-"], [class*=" icon-moon-"], [class^="icon-moon-"] span, [class*=" icon-moon-"] span{
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-moon-asistencni_sluzba .path1:before {
  content: "\e900";
  color: rgb(47, 84, 165);
}
.icon-moon-asistencni_sluzba .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-asistencni_sluzba .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-asistencni_sluzba .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-protiucet .path1:before {
  content: "\e904";
  color: rgb(45, 82, 165);
}
.icon-moon-protiucet .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-protiucet .path3:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-protiucet .path4:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(45, 82, 165);
}
.icon-moon-protiucet .path5:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(45, 82, 165);
}
.icon-moon-testovaci_jizda .path1:before {
  content: "\e909";
  color: rgb(47, 84, 165);
}
.icon-moon-testovaci_jizda .path2:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-testovaci_jizda .path3:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-zaruka .path1:before {
  content: "\e90c";
  color: rgb(47, 84, 165);
}
.icon-moon-zaruka .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-zaruka .path3:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-zaruka .path4:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-zaruka .path5:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(47, 84, 165);
}
.icon-moon-zaruka .path6:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(47, 84, 165);
}
.icon-moon-zaruka .path7:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-zaruka .path8:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(47, 84, 165);
}
.icon-moon-zkontrolovano_expertem .path1:before {
  content: "\e914";
  color: rgb(45, 82, 165);
}
.icon-moon-zkontrolovano_expertem .path2:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-zkontrolovano_expertem .path3:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-moon-zkontrolovano_expertem .path4:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(45, 82, 165);
}
.icon-moon-zkontrolovano_expertem .path5:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
