/*=========================
  GRID
 ========================== */

.wrapper {    
  width: 100%;  
   /* hiding swiper 10.10.2023, canceling OL banners, no substitution */ 
   /* 01.11. turn banners on again */
  min-height: 76vh;  
  z-index: 0;  
}

@media (max-width: 767px) {
    .text-left-xs {
      text-align: left !important;
    }
    .text-right-xs {
      text-align: right !important;
    }
    .text-center-xs {
      text-align: center !important;
    }

    .no-gutter-mobile > [class*='col-'] {
      padding-right:0;
      padding-left:0;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .text-left-sm {
      text-align: left !important;
    }
    .text-right-sm {
      text-align: right !important;
    }
    .text-center-sm {
      text-align: center !important;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .text-left-md {
      text-align: left !important;
    }
    .text-right-md {
      text-align: right !important;
    }
    .text-center-md {
      text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-left-lg {
      text-align: left !important;
    }
    .text-right-lg {
      text-align: right !important;
    }
    .text-center-lg {
      text-align: center !important;
    }
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

/* prebiti Bootstrap sirsiho containeru */
/*@media only screen and (min-width : 992px) { .container { width: 960px !important; } }*/

/* Bootstrap odstraneni mezer v gridu */
/*.row.no-gutter { margin-left: 0; margin-right: 0; }
.row.no-gutter [class*='col-']:not(:first-child),
.row.no-gutter [class*='col-']:not(:last-child) { padding-right: 0; padding-left: 0; }

.container-fluid.no-gutter, .no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}*/

/* u odkupu chceme mezery mezi bloky inputu */
/*body.repurchase .row.no-gutter [class*='col-']:not(:first-child),
body.repurchase .row.no-gutter [class*='col-']:not(:last-child) { 
  padding-right: 15px;
  padding-left: 15px; 
}*/

 
/*
.vertical-align, .vertical-align-left-bottom {
  display: flex;
  flex-direction: row;
}

.vertical-align > [class^="col-"],
.vertical-align > [class*=" col-"] {
  display: flex;
  align-items: center;     *//* Align the flex-items vertically */
  /*justify-content: center;*/ /* Optional, to align inner flex-items horizontally within the column  */
/*}*/

/*
.vertical-align-left-bottom > [class^="col-"],
.vertical-align-left-bottom > [class*=" col-"] {
  display: flex;
  align-self: flex-end;
}*/
