.container-fluid > .wide {
  width: inherit;
}


h3.news__item__title a, h3.news__item__title a:visited, h3.news__item__title a:hover { 
  /*font-size: 140%; */
  /*text-transform: uppercase;*/
  color: $dark-blue;  
}

.news__item__info a, .news__item__meta a {
	
	color: #007bff !important;;

}

.news__item__info a:after {
	
	content:" >";
	
}


.news__select-group {
	width: 300px;
	margin-left: auto;
	@include mobile {
		margin: auto;
	}
}
.news__item {
	margin: auto;
	margin-top: 2em;
	display: flex;
    //justify-content: space-between;

	@include mobile {
		display: block;
	}
}

.news__item__image {
	display: inline-block;
	flex: 0 0 250px;
	margin-right: 2em;
	img {
		width: 100%;
	}

	@include mobile {
		display: block;
		margin: auto;
		margin-bottom: 1rem;
	}
}

.news__item__meta {
	a {
		color: inherit;
	}
}

.news__item__title {
	margin: 1rem 0;
}

//.news__item__buttons {
//	margin-top: 1rem;
//	text-align: right;
//}
//
//.news__item__btn {
//	@extend .btn;
//	@extend .btn-primary;
//	display: inline-block;
//	padding: 1rem 2.5rem 1rem 2rem;
//	color: #fff;
//	&:hover {
//		color: #fff;
//	}
//}

.news__item__info > a {
    color: initial;
}

.news__item__info > a:hover {
  color: initial;
  text-decoration: underline;
}

.news-detail {
	margin-top: 50px;
	margin-bottom: 100px;
}

.news-detail__meta {
	margin-bottom: 2em;
	font-weight: bold;
	font-size: .9em;
	text-transform: uppercase;
	color: $gray;

	a {
		color: inherit;
		text-decoration: none;
	}
}

.news-detail__gallery {
	margin-top: 2em;
	a {
		display: inline-block;
		margin: 10px;
	}
	img {
		display: block;
	}
}
