/*=========================
  BASE FORMATTING
 ========================== */

* { font-family: Helvetica,"HelveticaNeue",Helvetica,Arial,sans-serif; }

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  font-size: 1rem;
  font-size: .9rem;
  line-height: 1.4;    
  margin: 0;
  padding: 0;
}

body {  
  background: #f1f3f2; /* lepsi bila:) */  
  background: #fff; 
  color: #000;
  -webkit-font-smoothing: antialiased;
  position: relative;
  margin:0;
  padding:0;
  padding-bottom: 16rem;
  min-height: 100%;
  @media (min-width: 990px) { padding-bottom: 12rem; }
  @media (min-width: 768px) and (max-width: 989px) { padding-bottom: 18rem; }
  @media (min-width: 530px) and (max-width: 768px) { padding-bottom: 20rem; }
  @media (max-width: 529px) { padding-bottom: 23rem; }
  @media (max-width: 350px) { padding-bottom: 28rem; }
  
  /*min-height: 100vh;  /*100% nejde */
}

em, .italic { font-style: italic;  font-family: Helvetica, "HelveticaNeue-Italic","Helvetica Neue",Helvetica,Arial,sans-serif !important; }
strong, b, .bold { font-weight: bold; font-family: Helvetica, "HelveticaNeue-Bold","helvetica-neue-bold", "Helvetica Neue",Helvetica,Arial,sans-serif !important; }
.light--font { font-family: Helvetica, "HelveticaNeue-Light","Helvetica Neue",Helvetica,Arial,sans-serif !important; }


a {   
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

a[href^="tel:"]:before {
  content: "\260e";
  margin-right: 0.5em;
}

p { 
  line-height: 150%; 
  font-size: 110%; 
  color: $dark-gray;
}

h1 {  
  font-weight: 600;  
  font-size: 300%;  
  text-align: left;
  /*text-transform: uppercase;*/
  color: #fff;
  margin-top: 0;  
}

h2 { 
  font-size: 180%; 
  /*text-transform: uppercase;*/
  color: $blue;
  /*padding: 2% 0;  */
}

h3 { 
  /*font-size: 140%; */
  /*text-transform: uppercase;*/
  color: $dark-blue;  
}

h4 { 
  /*font-size: 120%; */
  text-transform: uppercase;
  color: $dark-blue;    
}



h5 { 
  color: $dark-gray;
}


.fll { float: left; }
.flr { float: right; }
.nod { display: none; }
.cleaner, .clear { clear: both; }
.h30 { height: 30px;  }
.h20 { height: 20px;  }
.red { color: red;  }
.tcenter { text-align:center;  }
.text-blue { color: $blue;  }
.text-red { color: #d1222a; }
.text-grey { color: $gray; }
.bg--grey { background: $gray-bg; }
.text-transform-none { text-transform: none; }
.top5 { margin-top: 5%; }
.top4 { margin-top: 4%; }
.top3 { margin-top: 3%; }
.top2 { margin-top: 2%; }
.pad0 { padding: 0 !important; }
.mar0 { margin: 0 !important; }

.ul--no-list {
  
  list-style-type: none;    
  padding-left: 0; /* default je moc */
  
  li {
    
    color: $blue;    
    
  }
}

.ul--list-bullet {
  
  list-style-type: none;
  padding-left: 0;

  @media only screen and (max-width: 768px) {
      margin-bottom: 0;
  }
  
  li:before{
    content: "•";
    margin-right: 10px;
    font-weight: bold;
    color: $blue;
  }

}

.ul--list-bullet2 {
    margin-top: 1.5rem;

    @media only screen and (max-width: 768px) {
        margin-top: 0;
        margin-bottom: 0;

        margin-block-end: 0;
    }
}

.modal-content p { font-size: 80%; }  
.modal.fade.bottom { margin-top: 20vh; }
/*.modal.fade.bottom .modal-dialog { transform: translate3d(0, 100vh, 0); }*/
/*.modal.in.bottom .modal-dialog { transform: translate3d(0, 0, 0); }*/

/* hezci na open tab https://codepen.io/anon/pen/JOEPdY */
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #333;
  transition: transform 1s;
}
.caret:hover {
  transform: rotate(-180deg);
}

.blueimp-gallery a:not([href]):not([tabindex]) {
  color: #fff !important;
}

/* hacky na z tabu na pod sebou bloky dat v tabech */
@media(min-width:768px){

  .panel-heading{
    display:none;
  }  
  .panel{
    border:none;
    box-shadow:none;
  }
  .panel-collapse{
    height:auto;
    &.collapse{
      display:block;
    }
  }
}
/* hacky na z tabu na pod sebou bloky dat v tabech */
@media(max-width:767px){

  .tab-content{
    .tab-pane{
       display:block;
    }    
  }
   .nav-tabs{
      display:none;
  }
  .panel-title a{
    display:block;
  }
  .panel{
    margin:0;
    box-shadow:none;
    border-radius:0;
    margin-top:-2px;
  }
}


@media print {

  a[href]:after {
    content: none !important;
  }

  * { font-family: Arial, Helvetica, sans-serif; } /* na PCR nejaka zmet znaku */

  .container { /* https://getbootstrap.com/docs/4.0/getting-started/browsers-devices/#printing */
    width: auto;
  }

  .row { // jak je to asi flex, tak se to orizne vzdy na prvni stranku, tzn musi to byt blockle
    display: block;
    font-size: 100%;
  }  
}



.hidden {
    display:  none;

}


.fl {
  float:left;
}

.fr {
  float:right;
}


.clearfix {clear:both};


.pt5, .py5 {
    padding-top: 5px;

}

.pb5, .py5 {
    padding-bottom: 5px;

}

.pt10, .py10 {
    padding-top: 10px;

}

.pb10, .py10 {
    padding-bottom: 10px;

}

.pt15, .py15 {
    padding-top: 15px;

}

.pb15, .py15 {
    padding-bottom: 15px;

}

.pt20, .py20 {
    padding-top: 20px;

}

.pb20, .py20 {
    padding-bottom: 20px;

}

.pt25, .py25 {
    padding-top: 25px;

}

.pb25, .py25 {
    padding-bottom: 25px;

}

.pt30, .py30 {
    padding-top: 30px;

}

.pb30, .py30 {
    padding-bottom: 30px;

}

.pt35, .py35 {
    padding-top: 35px;

}

.pb35, .py35 {
    padding-bottom: 35px;

}

.pt40, .py40 {
    padding-top: 40px;

}

.pb40, .py40 {
    padding-bottom: 40px;

}

.pt50, .py50 {
    padding-top: 50px;

}

.pb50, .py50 {
    padding-bottom: 50px;

}

.pt60, .py60 {
    padding-top: 60px;

}

.pb60, .py60 {
    padding-bottom: 60px;

}


















.mt5, .my5 {
    margin-top: 5px;

}

.mb5, .my5 {
    margin-bottom: 5px;

}

.mt10, .my10 {
    margin-top: 10px;

}

.mb10, .my10 {
    margin-bottom: 10px;

}

.mt15, .my15 {
    margin-top: 15px;

}

.mb15, .my15 {
    margin-bottom: 15px;

}

.mt20, .my20 {
    margin-top: 20px;

}

.mb20, .my20 {
    margin-bottom: 20px;

}

.mt25, .my25 {
    margin-top: 25px;

}

.mb25, .my25 {
    margin-bottom: 25px;

}

.mt30, .my30 {
    margin-top: 30px;

}

.mb30, .my30 {
    margin-bottom: 30px;

}

.mt35, .my35 {
    margin-top: 35px;

}

.mb35, .my35 {
    margin-bottom: 35px;

}

.mt40, .my40 {
    margin-top: 40px;

}

.mb40, .my40 {
    margin-bottom: 40px;

}

.mt50, .my50 {
    margin-top: 50px;

}

.mb50, .my50 {
    margin-bottom: 50px;

}

.mt60, .my60 {
    margin-top: 60px;

}

.mb60, .my60 {
    margin-bottom: 60px;

}
















.pl0, .px0 {
    padding-left: 0px;

}

.pr0, .px0 {
    padding-right: 0px;

}



.pl5, .px5 {
    padding-left: 5px;

}

.pr5, .px5 {
    padding-right: 5px;

}

.pl10, .px10 {
    padding-left: 10px;

}

.pr10, .px10 {
    padding-right: 10px;

}

.pl15, .px15 {
    padding-left: 15px;

}

.pr15, .px15 {
    padding-right: 15px;

}

.pl20, .px20 {
    padding-left: 20px;

}

.pr20, .px20 {
    padding-right: 20px;

}

.pl25, .px25 {
    padding-left: 25px;

}

.pr25, .px25 {
    padding-right: 25px;

}

.pl30, .px30 {
    padding-left: 30px;

}

.pr30, .px30 {
    padding-right: 30px;

}

.pl35, .px35 {
    padding-left: 35px;

}

.pr35, .px35 {
    padding-right: 35px;

}

.pl40, .px40 {
    padding-left: 40px;

}

.pr40, .px40 {
    padding-right: 40px;

}

.pl50, .px50 {
    padding-left: 50px;

}

.pr50, .px50 {
    padding-right: 50px;

}

.pl60, .px60 {
    padding-left: 60px;

}

.pr60, .px60 {
    padding-right: 60px;

}












.ml0, .mx0 {
    margin-left: 0px;

}

.mr0, .mx0 {
    margin-right: 0px;

}





.ml5, .mx5 {
    margin-left: 5px;

}

.mr5, .mx5 {
    margin-right: 5px;

}

.ml10, .mx10 {
    margin-left: 10px;

}

.mr10, .mx10 {
    margin-right: 10px;

}

.ml15, .mx15 {
    margin-left: 15px;

}

.mr15, .mx15 {
    margin-right: 15px;

}

.ml20, .mx20 {
    margin-left: 20px;

}

.mr20, .mx20 {
    margin-right: 20px;

}

.ml25, .mx25 {
    margin-left: 25px;

}

.mr25, .mx25 {
    margin-right: 25px;

}

.ml30, .mx30 {
    margin-left: 30px;

}

.mr30, .mx30 {
    margin-right: 30px;

}

.ml35, .mx35 {
    margin-left: 35px;

}

.mr35, .mx35 {
    margin-right: 35px;

}

.ml40, .mx40 {
    margin-left: 40px;

}

.mr40, .mx40 {
    margin-right: 40px;

}

.ml50, .mx50 {
    margin-left: 50px;

}

.mr50, .mx50 {
    margin-right: 50px;

}

.ml60, .mx60 {
    margin-left: 60px;

}

.mr60, .mx60 {
    margin-right: 60px;

}


