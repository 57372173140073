/*=========================
  Default Form
 ========================== */

$input-height : 33px;

select {	
	width: 100%;	
  border-radius: 0
}

.prestavby .select2-container { width: 25% !important; }

.select2-container--default .select2-selection--single { /* nutne k zachovani stylu select2 */
    padding:3px;
    height: 34px;     
    font-size: 1em; 
    font-size: 1rem;
    color: #000;
    font-weight: normal;      
    position: relative;
    border-radius: none;
}

ul[id^="select2-dealer"] .select2-results__option { /* filter  block - je mimo DOM, tzn takto napevno, neni vzdy fix nazev ID.. */
    font-size: .8rem;
}


.select2-container--default .select2-selection--single .select2-selection__arrow {    /* nutne k zachovani stylu select2 */
    border-left: 1px solid #aaa;
    color: #fff;        
    font-size: 1em;
    font-weight: normal;    
    padding: 14px 12px;
    height: 32px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
} 


/* anim checkboxes via https://bootsnipp.com/snippets/featured/animated-radios-amp-checkboxes-nojs */
.checkbox, .radio {

  label:after, 
  label:after {
        content: '';
        display: table;
        clear: both;
  }

  .cr {
      position: relative;
      display: inline-block;
      border: 1px solid #a9a9a9;
      border-radius: .25em;
      width: 1.3em;
      height: 1.3em;
      float: left;
      margin-right: .5em;

      .cr-icon {
          position: absolute;
          font-size: .8em;
          line-height: 0;
          top: 50%;
          left: 20%;
      }
  }

  label input[type="checkbox"],
  label input[type="radio"] {
      display: none;
  }

  label input[type="checkbox"] + .cr > .cr-icon,
  label input[type="radio"] + .cr > .cr-icon {
      transform: scale(3) rotateZ(-20deg);
      opacity: 0;
      transition: all .3s ease-in;
  }

  label input[type="checkbox"]:checked + .cr > .cr-icon,
  label input[type="radio"]:checked + .cr > .cr-icon {
      transform: scale(1) rotateZ(0deg);
      opacity: 1;
  }

  label input[type="checkbox"]:disabled + .cr,
  label input[type="radio"]:disabled + .cr {
      opacity: .5;
  }

}
.radio .cr { border-radius: 50%; }
.radio .cr .cr-icon { margin-left: 0.04em; }



/* MAIN SEARCH FORM ON RESULTS PAGE */
.filter {

  h3 {
    font-size: 120%;
    text-transform: uppercase;
    color: $gray;
    padding: 5px 0px 0px 40px;
    margin-bottom: 1rem;
    background: url("/img/layout/ico-search-define.png") no-repeat scroll left center;    
  }

  h6 {
    font-size: 90%;
    margin-top: .6rem;
    text-transform: uppercase;
    color: $gray;
    font-weight: bold;
  }

  label { 
    color: grey; 
    display: block;
    text-transform: uppercase;
    margin-bottom: .1rem;
    font-weight: bold;
    font-size: 90%;
  }

  .select2-container--default .select2-selection--single{ 
    font-size: .8rem;
  }

  .col-10 label { cursor: pointer; }

  input[type='text'] { 
    width: 100%;
    display: block;        
    border: 1px solid blue;    
  }

  span.geo_search { 
    font-size: 80%; 
    color: grey;
    a {
      text-decoration: underline;
    }
  }

  .brands  {
    
    max-height: 100px;    
    overflow-y: auto;
    overflow-x: hidden;

    label{ 
      color: $blue; 
      width: 100%;
      display: inline-block;
      text-transform: none;      
      font-weight: normal;
      font-size: 80%;
    }
    

    input[type='checkbox'] {      
      display: inline-block;
      width: auto;      
    }
  }

  .label-slider {
    font-size: 80%;
    text-transform: uppercase;
    color: $blue;
    display: inline;
    
    span {
      font-weight: bold;    
    }
    
  }

  .slider.slider-horizontal {
    
    width: 100%;
    
    .slider-selection { background: $gray; }

    .slider-handle { background: $blue; }

  }

  .cars_count {
    @include mobile { font-size: 1.5rem }
  }


}
/* END.MAIN SEARCH FORM ON RESULTS PAGE */

.filter__geo-search .ap-input-icon { display:  none;  }

.filter__brands-more {
  font-size: 80%;
  display: block;
  padding-left: 30px;
  text-decoration: underline;
  background: url("/img/layout/ico-search-more.png") no-repeat scroll left center;    
  &:hover { text-decoration: none; }
}

input[type='text'].search { 
    width: 100%;
    min-height: 42px;
    display: block;
    padding: 1px 47px 1px 7px;
    background: url("/img/layout/ico-search-input.png") no-repeat scroll right center;    
    border: 1px solid $gray;
    font-size: 90%;
}

.btn-primary { 
  height: auto;  
  width: auto;
  background: $blue;
  padding: 6% 12% 6% 12%;
  text-transform: uppercase;
  margin: 0px;
  font-size: 100%;
  border-radius: 0px; 
  border: none;   
  box-shadow: none;   
  cursor: pointer;
  background: url("/img/layout/button-arrow-right.png") no-repeat 90% center $blue;
}

.btn-terms {
  padding: 4% 9% 4% 9%;
}

.page--404 .btn-primary {
  padding: 1rem 3rem 1rem 2rem;
}

.with-errors p { 
  color: red;
  font-weight: bolder;
  font-size: 90%; 
  padding: .5rem 0;
}

.input-group-addon {
  /*cursor: help;*/
}

.input-group-addon-2 {
  padding: .1rem .4rem;
  font-size: 90%;
  color: #495057;
  
}

.headline__sort {
  
  .select2-container--default .select2-selection--single { 
      padding: 5px 0px 1px 45px;            
      height: 40px;       
      font-size: 100%;      
      background: url("/img/layout/ico-select-sort.png") no-repeat scroll left center;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow {    /* nutne k zachovani stylu select2 */
      border-left: 0px;
      color: $blue;              
      padding: 16px 12px;      
      height: 40px;      
      width: 20px;
  } 

  .select2-selection__rendered { color: $gray !important; }

}