/*=========================
  FONTS - https://css-tricks.com/font-display-masses/
 ========================== */

$blue-font-color : #007edb;
 
.fs70 { font-size: 70% }
.fs80 { font-size: 80% }
.fs100 { font-size: 100% }
.blue { color: $blue-font-color; }

@font-face {
  font-family: 'HelveticaNeueRoman';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/HelveticaKVAFont.eot');
  src: local('/fonts/HelveticaKVAFont'), 
       url('/fonts/HelveticaKVAFont.woff') format('woff'), 
       url('/fonts/HelveticaKVAFont.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('/fonts/HelveticaNeue.eot');
  src: url('/fonts/HelveticaNeue.woff2') format('woff2'),
       url('/fonts/HelveticaNeue.woff') format('woff'),
       url('/fonts/HelveticaNeue.ttf') format('truetype'),
       url('/fonts/HelveticaNeue.svg#HelveticaNeue') format('svg'),
       url('/fonts/HelveticaNeue.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: url('/fonts/HelveticaNeue-Bold.woff') format('woff'),
       url('/fonts/HelveticaNeue-Bold.ttf') format('truetype'),
       url('/fonts/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'helvetica-neue-bold';
  src: url('/fonts/helvetica-neue-bold.eot');
  src: url('/fonts/helvetica-neue-bold.woff2') format('woff2'),
       url('/fonts/helvetica-neue-bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue-Italic';
  src: url('/fonts/HelveticaNeue-Italic.woff') format('woff'),
       url('/fonts/HelveticaNeue-Italic.ttf') format('truetype'),
       url('/fonts/HelveticaNeue-Italic.svg#HelveticaNeue-Italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue-Light';
  src: url('/fonts/HelveticaNeue-Light.eot');
  src: url('/fonts/HelveticaNeue-Light.woff2') format('woff2'),
       url('/fonts/HelveticaNeue-Light.woff') format('woff'),
       url('/fonts/HelveticaNeue-Light.ttf') format('truetype'),
       url('/fonts/HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg'),
       url('/fonts/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}