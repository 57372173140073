/*=========================
  products + list
 ========================== */

.list, .cars__slider  {

    a:hover, a:focus, a:focus, a:focus { text-decoration: none; }

    .card-car
    {
        border: 1px solid $gray-opacity;
        background: -moz-linear-gradient(top,  rgba(0,0,0,0.03) 0%, rgba(0,0,0,0) 100%);
        background: -webkit-linear-gradient(top,  rgba(0,0,0,0.03) 0%,rgba(0,0,0,0) 100%);
        background: linear-gradient(to bottom,  rgba(0,0,0,0.03) 0%,rgba(0,0,0,0) 100%);
        /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a0300000', endColorstr='#00000000',GradientType=0 );*/
        padding: 0.6rem ;
        position: relative;
        z-index: 90;

        &:hover {
            background-color: rgba(0,0,0,0.03);
            transition: all .5s ease-in-out;
            box-shadow: 0 5px 15px -5px #afa7a7;
        }

        h5 {
            font-size: 1.1rem;
            line-height: 1.4rem;
            margin: 0;
            color: $blue;
            padding-bottom: .5rem;
        }

        p.card-car__subtitle {
            color: grey;
            font-size: 80%;
        }

        p.card-car__payment {
          color: grey;
        }

        p.card-car__subtitle2 {
          line-height: initial;
          margin-bottom: 0.8rem;
        }

        .car-name-no-OL {
          padding-bottom: 1.1rem !important;
        }
        .car-name-OL {
          padding-bottom: 0.8rem !important;
        }

        .card-car__thumbnail-img {

          min-height: 167px;
          position: relative;

          img {
              /*&.detail { border: 1px solid red; }*/
              transition: all .3s ease-in-out;
              margin-bottom: 1rem;
          }

          &:hover img { transform: scale(1.1); }

          &.list { min-height: 200px; }
        }


        .card-car__specification {

			&.specification_price {
				min-height: 4rem;

			}

            div.col-3 {
                height: 4rem;
                font-size: 70%;
                color: grey;
                margin-bottom: 0.7rem;

                div {

                    height: 60%;
                    &.fuel  { background: url("/img/layout/ico-list-fuel.png") no-repeat top center transparent; }
                    &.km    { background: url("/img/layout/ico-list-km.png") no-repeat top center transparent; }
                    &.year  { background: url("/img/layout/ico-list-calendar.png") no-repeat top center transparent; }
                    &.trans { background: url("/img/layout/ico-list-trans.png") no-repeat top center transparent; }
                }
            }

			.operative_leasing_price {
				color: #0855ad !important;
				font-weight: bold;
			}

        }

        .card-car__distance div { font-size: .8rem; }
        .card-car__distance2 { margin-top: -0.85rem;}

        a.btn { font-size: 80%; }
        a.btn--fs100 { font-size: 100%; }
    }


    /* prepis pro slider na detailu */
    &.cars__slider__detail {

        h5 { font-size: 75%; padding-bottom: 0; line-height: 1rem; }
        .card-car__specification { text-align: center; font-size: 70%; color: grey; margin-bottom: .4rem; }
        img { margin-bottom: .2rem; }
    }

    /* prepis pagination na listu */
    .page-item.active .page-link {
        background-color: $blue;
        border-color: $blue;
    }
    .page-link { color: $blue; }
    .page-item.disabled span { padding: 0 1rem; }

    .slick-list { margin-bottom: 1rem; }

}

.cars__slider__tabs {

    font-size: 90%;

    @include desktop {
        background: url("/img/layout/nav-border-right.gif") no-repeat right center;
        &:nth-last-of-type(1) { background: none; }
    }

    @include mobile {
        padding-top: 1rem;
    }

    &.active a { text-decoration: underline; }
}



.products {

    p {
        font-size: 100%;
        line-height: 150%;
        margin-bottom: .5rem;
    }
}


/* hp__car-slider--choosen */

.hp__car-slider--choosen {
  /*overflow-x: hidden; - pretejka asi kvul ilivechatu*/
}

.col-item
{
    background: #FFF;
    padding-top: 0.5rem;
    margin-right: 1rem;
    margin-left: 1rem;

    .photo {

      height: 140px;

      img {
        margin: 0 auto;
        width: 100%;
        @media (max-width: 480px) { width: auto; }
        max-height: 140px;
        @media (min-width: 992px) and (max-width: 1366px) {
          width: auto;
        }
      }
    }

    .info {
        padding: 0.5rem;

        h5 {
            line-height: 1.5rem;
            margin: 0;
            font-size: 100%;
            font-weight: bolder;
            span { font-size: 80%; }
        }

        p {
            line-height: 1.5rem;
            margin: 0;
            font-size: 80%;
        }

        a { font-size: 90%; }
    }
}

.ribbon-hover-wrapper {
  position: absolute;
  width: 100%;
  height: 200px;
  /* z-index: 950; */
  z-index: 29;

  .ribbon-wrapper {
    overflow: hidden;
    position: absolute;
    z-index: 900;
    width: 190px;
    height: 210px;
    top: -10px;
    right: 0px;

      .ribbon {
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        text-shadow: rgba(255,255,255,0.5) 0px 1px 0px;
        -webkit-transform: rotate(45deg);
        -moz-transform:    rotate(45deg);
        -ms-transform:     rotate(45deg);
        -o-transform:      rotate(45deg);

        position: relative;
        left: -50px;
        top: 20px;
        padding: 5px 0;
        width: 360px;
        font-size: .9rem;
        &--pvv {
          /*left: -53px;
          top: 22px;      */
          left: -40px;
          top: 23px;
          span {
            font-size: .9rem;
            line-height: 1.2rem;
            display: inline-block;
          }
        }

        &--normal {
          left: -40px;
          top: 23px;
        }

        background-color: $blue;
        background-image: -webkit-gradient(linear, left top, left bottom, from($blue), to(#459AC2));
        background-image: -webkit-linear-gradient(top, $blue, #459AC2);
        background-image:    -moz-linear-gradient(top, $blue, #459AC2);
        background-image:     -ms-linear-gradient(top, $blue, #459AC2);
        background-image:      -o-linear-gradient(top, $blue, #459AC2);
        color: #fff;
        -webkit-box-shadow: 0px 0px 3px rgba(0,0,0,0.3);
        -moz-box-shadow:    0px 0px 3px rgba(0,0,0,0.3);
        box-shadow:         0px 0px 3px rgba(0,0,0,0.3);
      }

      .ribbon:before, .ribbon:after {
        content: "";
        /*border-top:   3px solid #6e8900;   */
        border-left:  3px solid transparent;
        border-right: 3px solid transparent;
        position:absolute;
        bottom: -3px;
      }

      .ribbon:after {
        right: 0;
      }​
      .ribbon:before {
        left: 0;
      }

  }

}


[data-slide="prev"]
{
    margin-right: 10px;
}









.masonry {
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  height: 800px;

  /*@media screen and (min-width: 400px) {
    height: 1600px;
  }

  @media screen and (min-width: 600px) {
    height: 1300px;
  }

  @media screen and (min-width: 800px) {
    height: 1100px;
  }

  @media screen and (min-width: 1100px) {
    height: 800px;
  }*/
}

.item {
  box-sizing: border-box;
  padding: 10px;
  counter-increment: item-counter;

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 220px;
    font-size: 10px;
    background: currentColor;
    box-sizing: border-box;


    &:before {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 13px;
      width: 2em;
      height: 2em;
      line-height: 2em;
      text-align: center;
      font-weight: bold;

      content: counter(item-counter);
    }

    &:after {

      content: 'ಠ‿ಠ';
    }

    &--small {

      height: 100px;

      &:before {

      }

      &:after {
        content: '♥◡♥';
      }
    }

    &--medium {

      height: 175px;

      &:before {

      }

      &:after {
        content: '◔ᴗ◔';
      }
    }

    &--large {

      height: 280px;

      &:before {

      }

      &:after {
        content: 'ಠ_๏';
      }
    }
  }
}
