/*=========================
  swiper-slider
 ========================== */
  

  .swiper-container-a, .swiper-container-down-a {
    width: 100%;
    height: 376px;
    position:relative;
    margin:0px;
    
    & .swiper-slide {
    
    background-size: cover;
    
    background-position-X: 47%;


        @media (min-width:1450px) {
            background-position-X: 47%;

        }

        @media (max-width:1450px) {
            background-position-X: 74%;
            background-position-X: 47%;

        }

        @include tablet {
           background-position-X: 82%;
            background-position-X: 60%;

        }

        @include mobile {
           background-position-X: 82%;
            //background-position-X: 65%;//before closed
            background-position-X: 53%;

        }

        




        /* Box s nadpisem a buttonem */
        & .slide-header {

            display:none;

            margin-top:90px;

            @include desktop {

                padding:0;

            }
            

          & a {

            text-decoration: none;

             &:hover{

                 text-decoration: none;

             }

          }

          & h2 {
            color:white; 

          }

          
          & .btn-swiper {

              height:35px;
              line-height:35px;
              margin-top:25px;
              width: 110px;
              display: block;
              padding:0;

          }

        }

    }

   
    & .swiper-navigation {

      @include tablet {

        display:none;

      }


      @include mobile {

        display:none;

      }

    }


     /* navigation bullets */
    & .swiper-pagination{
        
        bottom:114px;

        & .swiper-pagination-bullet {

          border: 2px solid white;
          margin-right:10px;
          opacity:0.75;

        }

    }
  }

