
.benefits {
    &__container { 
        margin: 0px auto;
        padding-bottom: 60px;
        margin-bottom:50px;

    }
    
    &__heading {
        
        
    }
    
    
    &__icon-holder {
        display: block;
        overflow:hidden;
        width: 95px;
        height: 95px;
        margin: 0 -30px;
        position: relative;
        font-size: 90px;
        
        @include tablet { 
            margin: 0 auto;
            margin-bottom: 15px;  
            font-size: 70px; 
            width: 75px;
            height: 75px;     
        } 
        
        
        @include mobile { 
            margin: 0 auto;
            margin-bottom: 10px;  
            font-size: 90px;  
            font-size: 50px; 
            width: 55px;
            height: 55px;      
        }
       
        
        
        
    }
    
    
    &__moreinfo {
        color: #0855ad;
        cursor:pointer;
        color: #007bff !important;;


            &:after {
                
                content:" >";
                
            }

    }
    
    
    
    
    &__icon {
        position: absolute;
        display: block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        
        
        
    }
    
    
    &__heading {
        
        padding-left:30px;
        margin-top:40px;
        
            @include tablet { 
                padding-left: 0;
                text-align:center;
                margin-top: 50px;
            }
            
            
            @include mobile { 
                padding-left: 0;
                text-align:center;
                margin-top: 40px;
                font-size: 24px;
            }
        
    }
    
    &__subheading {
        color: inherit;
        font-size: 15px;
        font-weight: bold;
    }
    
    
    &__text   {
        
        
        
        &--inner {
           background-color: #f7f7f9;
           float:left;
           padding:30px;
           height:100%;
           
           & b {
                font-weight:bolder;
           }
           
           & ul {
                list-style-type:none;
                padding-left: 0px;
                
                & li {
                
                    &:before {
                        content: "> ";
                        color: #0855ad;   
                            
                    }
                  
                }
           }
           
           & p:last-child {
                margin-bottom: 0px;
           }
        }
    }
    
    


}



.modal-zaruka {
    & h3 {
        color: rgb(8, 85, 173);
        text-align:center;
        font-size:18px;
        margin-bottom:10px;
        margin-top:25px;
    }

    & p {
        text-align: left;

    }

    & ul {
    margin-bottom:25px;
    font-weight: 100;
    list-style-type:none;
    padding-left: 0px;
    text-align:left;
    font-size: 12px;

        & li {

            padding-left:20px;
            margin-bottom:6px;

            &:before {
                content: "> ";
                color: #0855ad; 
                position: absolute;
                left: 27px;
                font-weight: 800;

            }

        }
    }
  
    & .modal-body {
          padding:25px;

    }
 
     & .modal-dialog {
        min-width:800px;

          @include tablet { 
            
            min-width:80%;   
        } 
        
        
        @include mobile { 
            min-width:90%;    
        }
      


       & .tg {
        
border-collapse:collapse;
        border-spacing:0;

        @include tablet { 
            overflow-x: scroll;
               
        } 
        
        
        @include mobile { 
               
        }
        
            & td {
               
                font-size:12px;
                padding:10px;
                border-style:solid;
                border-width:1px;
                overflow:hidden;
                word-break:normal;
                border-color:#ddd;
                min-height:30px;

                }
                 & th {
                font-size:12px;
                padding:10px 5px;
                border-style:solid;
                border-width:1px;
                overflow:hidden;
                word-break:normal;
                border-color:#ddd;
                }

            & .tg-yzja {
                
                text-align:center;
                }

            & .tg-ggx3 {
                
                padding:0px 25px;
                color:#0855ad;
                text-align:left;
                padding: 15px;
                font-weight:800;

               }

            .tg-25i6 {
                color:#343434;
                text-align:left;
                vertical-align:top;
                border-color:#ddd;
                font-weight: 100;
                }

           

        

    }

      
  }

}
