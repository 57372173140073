/*=========================
  Zpetny odkup - HP
 ========================== */

@-webkit-keyframes 
  icon-beat {  0% {
   -webkit-transform: scale(2);
   -ms-transform: scale(2);
   transform: scale(2);
} 100% {
   -webkit-transform: scale(1);
   -ms-transform: scale(1);
   transform: scale(1);
  }
}
 
@keyframes icon-beat {  
	0% {
	 -webkit-transform: scale(2);
	 -ms-transform: scale(2);
	 transform: scale(2);
	} 100% {
	 -webkit-transform: scale(1);
	 -ms-transform: scale(1);
	 transform: scale(1);
	}
}


.repurchase {

	.repurchase--bg {
		background: url("/img/layout/common-repurchase--1280x.jpg") no-repeat center center $gray;
		/*min-height: 480px;*/
		min-height: 240px;

		/*.repurchase--advantage-block {
			
			-webkit-border-radius: 20px;
			-moz-border-radius: 20px;
			border-radius: 20px;			
			border: 10px solid $blue-opacity;
			background: $gray-opacity-8;
			-webkit-box-shadow: $gray 1px 1px 1px;;
			-moz-box-shadow: $gray 1px 1px 1px;; 
			box-shadow: $gray 1px 1px 1px;;				

			ul li { line-height: 190%; }
		}*/		 
	}

	.btn-primary {
		padding: 2rem 3rem;
	}

	label {
	  display: block;
	  position: relative;
	  padding: 0 1rem;
	  line-height: 28px;
	  font-weight: normal;
	  cursor: pointer;
	  -webkit-tap-highlight-color: transparent;	  
	}

	.legalnote label { padding: 0; }

	label:last-of-type { margin-right: 1rem; }

	label i {
	  display: inline-block;	    
	  position: relative;
	  top: 6px;
	  font-style: normal;
	  color: #666666;
	}

	label span {
	  display: inline-block;
	  margin-left: 5px;
	  line-height: 25px;  
	}

	label span.legalnote { 
		font-size: 90%; 
		padding-left: 0px; 
		line-height: 35px;
	}	 

	input[type="radio"],
	input[type="checkbox"] { display: none; }

	input[type="radio"] + i:before,
	input[type="checkbox"] + i:before {
	  font-family: 'FontAwesome';
	  font-size: 28px;
	  height: 25px;
	  width: 25px;
	  display: inline-block;
	}

	/*div.legalnote { padding-top: 30px; }*/
	div.legalnote label { margin-right: 0; }
	div.legalnote input[type="checkbox"] + i:before, div.legalnote input[type="checkbox"] + i {	  
	  height: 35px;	  
	  top: 6px;	  
	}

	input[type="radio"]:checked + i,
	input[type="checkbox"]:checked + i {
	  position: relative;
	  -webkit-animation: icon-beat 0.1s ease;
	  animation: icon-beat 0.1s ease;
	}

	input[type="radio"]:checked + i + span,
	input[type="checkbox"]:checked + i + span {
	  -webkit-transition: all 0.1s ease;
	  transition: all 0.1s ease;
	}

	input[type="radio"] + i:before { content: "\f10c"; }

	input[type="radio"]:checked + i:before { content: "\f111"; }

	input[type="radio"]:checked + i + span,
	input[type="radio"]:checked + i:before { color: rgba(0, 126, 219, 1); }

	input[type="checkbox"] + i:before { content: "\f096"; }

	input[type="checkbox"]:checked + i:before { content: "\f046"; }

	input[type="checkbox"]:checked + i + span,
	input[type="checkbox"]:checked + i:before { color: rgba(0, 126, 219, 1); }

	.form-horizontal label { float: left; }
}