/*=========================
  Footer
 ========================== */
.footer-line { 	/* odsazeni od wrapperu */
		
	/*height: $footerHeight;*/
	position: absolute;
	right: 0;
	bottom: -60px;
	left: 0;
	padding: 0;
	margin: 0;

	/*@media (min-width: 768px) and (max-width: 991px) { height: 23rem; }
	@media (max-width: 767px) { height: 16rem; }*/

	footer { 	

	    background-color: #dfe6e8;
	    color: $gray;
	    text-transform: uppercase;
	    font-size: 90%;
		width: 100%;		
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 0;
		margin: 0;

		@include desktop { /* absolutne kvuli odsazeni celeho footeru od spoda, na small devices pak prekryva content nad tim... */
			/*position: absolute;
			right: 0;
			bottom: 0;
			left: 0;*/
		}

		@include tablet { /* absolutne kvuli odsazeni celeho footeru od spoda, na small devices pak prekryva content nad tim... */
			/*position: absolute;
			right: 0;
			bottom: 0;
			left: 0;*/
		}

		a { 
			color: $gray; 
			font-weight: bolder;
			&:hover { color: $blue; text-decoration: underline;  }
		}
	    	   
	    .row {
	    	margin: 1rem 0; 
	    }

	    .nav-link { padding: .3rem .8rem; }
	}

}


@media only screen and (max-width: 1000px) {

	.pdf-download {
		margin-bottom: 5rem;
	}
}


.social {
	margin-top: 10px;
	a {
		display: inline-block;
		text-align: center;
		font-size: 20px;
		height: 28px;
		width: 28px;
		border-radius: 3px;
		color: #fff !important;
		background-color: #5f6c74;
		transition: all .5s;
		&:hover {
			background-color: #1b5da5;
		}
	}
}
