

.branches-h2 {
 // margin-top: 8px; pro verzi 2
    margin-top: 25px;
    text-align: center;  

}

.container-fluid-page-head {
    & button {

      height: 42px;
      color: white;
      border-color:#0855ad;
      background-color:#0855ad;
      line-height: 42px;

      &:hover {
        color: #0855ad;
        background-color: white;
        cursor: pointer;

      }


    }


    & label {
          color: #9896a5;
          width :100%;
          display: inline;

          &:nth-child(2) {
            display:none;

          }

          @include tablet {

            
            
          }

          @media (max-width:1200px) {

          font-size: 12px;
          line-height: 12px;

      }

          @include mobile {

           
          

          }
    }

   
    & .checkbox .cr {
      color: #0855ad !important;

      @media (max-width:1200px) {

          top: 4px;

      }

      @media (max-width:800px) {

          top:3px;

      }

    }

    & .lg-3-desktop-relative {

      @media (min-width:1200px) {

          position: relative;
          left: -65px;

      }

    }

}


.container-fluid-tabs {
  overflow-x: hidden;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 63px;
  margin-top: 15px;
}

.contact__icon-holder {
    width:100%;
    min-height:135px;
    position:  relative;
    overflow:hidden;   
    margin-bottom:25px;

}


.contact__icon-holder img {
    width:100%;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate( -50%, -50%);
    -moz-transform: translate( -50%, -50%);
    -webkit-transform: translate( -50%, -50%);
    -ms-transform: translate( -50%, -50%);
    -o-transform: translate( -50%, -50%);
    transition: 0.3s all ease;
    -moz-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    -ms-transition: 0.3s all ease;
    
    
}

 .contact__tel a[href^="tel:"]:before {
    display:none;

}


.contact__icon-holder img:hover {
    transform: translate( -50%, -50%) scale(1.1);
    -moz-transform: translate( -50%, -50%) scale(1.1);
    -webkit-transform: translate( -50%, -50%) scale(1.1);
    -ms-transform: translate( -50%, -50%) scale(1.1);
    -o-transform: translate( -50%, -50%) scale(1.1);
    transition: 0.3s all ease;
    -moz-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    -ms-transition: 0.3s all ease;
    cursor:pointer;
    
}    


.contact__heading {
    font-size: 18px;

    &:hover {
    text-decoration: underline;
    cursor: pointer;
}

}

.contact__tab {
    padding:15px;
    background-color: #eee ;
    border:1px solid #ddd;
    margin-top:15px;
    margin-bottom: 15px;
    color: #0855ad;
    min-height: 267px;
    
    & address:hover {
      text-decoration:underline;
      cursor:pointer;

    }
    
}

.contact__button {
    background-color: #0855ad;
    color: white;
    text-transform:uppercase;
    width:100% ;
    text-align:center;
    height:35px;  
    border:1px solid;  
    transition: 0.3s all ease;
    -moz-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    -ms-transition: 0.3s all ease;
    
    
}

.contact__button:hover {
    background-color: #0855ad;
    
    
}


.contact__button a {
    font-size: 100%;
    color: white;
    transition: 0.3s all ease;
    -moz-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    -ms-transition: 0.3s all ease;

}

.contact__button:hover a {
    color: white;
    
    
}

.ol_button {
    width: 35%;

    @media only screen and (max-width: 1200px) {
        width: 50%;
    }

    @media only screen and (max-width: 1024px) {
        width: 50%;
    }

    @media only screen and (max-width: 768px) {
        margin-top: 2rem;
        width: 100%;
    }
}

.contact__tab  ul {
   list-style-type: none;
   padding: 0;
    
}

.contact__button a {
    line-height: 35px;
    DISPLAY:BLOCK;
    margin:0;
    padding:0;
    text-decoration: none;

    @include mobile {

    font-size: 11px;
    }
    
  
}

.contact-tab-template {
  background: transparent;
  width: 250px;
  height:150px;
  margin:0;
  padding:0;
  font-size: 12px;
  border: 0px;



  & address {

      @include mobile {
          text-align: center;

      }

      &:hover {
      text-decoration:none;
      cursor:auto;
    }
}

}

.contact-tab-template .contact__info, .contact-tab-template .contact__img {
    width: 50%;
    float: left;
    

}

.contact-tab-template  .contact__info {
 
    @include mobile {
        
        position: absolute;
        top: 50%;
        left: 50%;
        text-align: center;
        transform: translate(-50%, -50%);
        min-width:250px;

    }
}

.contact-tab-template .contact__heading {
    font-size: 12px;
    font-weight: 600;
    margin-bottom:15px;
    text-transform: uppercase;
    margin: 10px 0;
    margin-top :25px;
    display: block;

    &:hover {
    cursor: auto;
    text-decoration: none;
}


    @include mobile {
      
      width: 117px;
      text-align: center;
      margin: 10px auto;
}

    }



.contact-tab-template .contact__icon-holder {
   height:80px;
   padding:10px;

}

.contact-tab-template .contact__img {
    padding:10px;
    padding-right:0;
    padding-top:25px;
    position: relative;


    @include mobile {

        display:none;

    }

}

.contact-tab-template .contact__img img {
    width: 100%;


}


.contact-tab-template .contact__button {
    background: #0855ad;
    transition: 0.3s all ease;
    -moz-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    -ms-transition: 0.3s all ease;


}


.contact-tab-template .contact__button:hover {
    background: white;


}



.gm-style-iw > div {

  overflow:hidden !important;
}

.contact-tab-template .contact__button a {
    color: white;
    font-size:10px;
    transition: 0.3s all ease;
    -moz-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    -ms-transition: 0.3s all ease;

    &:hover {
      background-color: #0855ad; 

    }

}

.contact-tab-template .contact__button:hover a {
    color:white;

}


@media (max-width:1200px) {

  .contact__tab .col-6:nth-child(2){
      padding-left:0;

  }

  .contact__tab {
    min-height: 288px;
}


.contact__heading {
      font-size:15px;

  }
}


@media (max-width:992px) {

  .contact__tab .col-6:nth-child(2){
      padding-left:0px;


  }

  .contact__info {

    font-size: 12px;
  }


  .contact__heading {
      font-size:15px;

  }
}



@media (max-width:768px) {

    .contact__heading {
      font-size:18px;

  }

  .contact__tab {
    min-height: unset;
}

  .contact__info {

    font-size: 14px;

  }

  .contact__tab .col-6:nth-child(2){
      padding-left:15px;


  }


  }




.button-mobile-only {

    display:none;
    max-width: 142px;
    margin: 0 auto;

    @include mobile {
        display:block
} 

}



.button-not-mobile {

    display:block;

    @include mobile {
        display:none;
} 

}






@media (max-width:576px) {

  .contact__tab .col-6:nth-child(2){
      padding-left:0px;

  }

   .contact__info {

    font-size: 12px;
  }

  .contact__heading {
      font-size:15px;

  }
}

