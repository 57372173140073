/*=========================
  Car DETAIL
 ========================== */

.divider {
  content: " ";
  background: #cdcdcd;
  height: 0.5rem;
  margin: 4% 0 4% 0;
  padding: 0;
}

.blueimp-gallery {
  .prev,
  .next {
    color: #fff;
  }
}

.gallery {
  .small div {
    padding-right: 0;
    padding-top: 15px;
  }

  .gallery__panorama__wrapper {
    padding: 0 10px;

    .init-panorama-container,
    #gallery__panorama {
      width: 100%;
      height: 480px;
    }
  }

  a {
    position: relative;
    display: inline-block;
    &[data-remaining]:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      line-height: 90px;
      font-size: 25px;
      color: #fff;
      background-color: rgba(0, 0, 0, 0.4);
      content: attr(data-remaining);
    }
  }
}

.btn360 {
  display: block;
  position: absolute;
  bottom: 0.5rem;
  right: 1.5rem;

  width: 60px;
  height: 60px;
  border: 0;
  background: transparent url("/img/layout/360-icon.svg") center center
    no-repeat;
  background-size: cover;

  span {
    display: none;
  }
}

.btn__promo--default {
	display: block;
	position: absolute;
	top: 8rem;
	left: 0.5rem;
	width: 50px;
	height: 51px;
	border: 0;
	background: transparent url("/img/layout/list__promo--default.png")
		center center no-repeat;
	background-size: cover;
	z-index: 30;

	@media (max-width: 1200px) and (min-width: 992px) {
		top: 6rem;
	}

	@media (max-width: 991px) and (min-width: 576px) {
		top: 7.5rem;
	}
}

.btn__promo--2-roky-zaruka {
  display: block;
  // position: absolute;
  // top: .3rem;
  // left: .5rem;
  width: 70px;
  height: 40px;
  border: 0;
  background: transparent url("/img/layout/list__promo--2-roky-zaruka-v2.png")
    center center no-repeat;
  background-size: cover;
  z-index: 30;
}

.btn__promo--absolute_position {
	display: block;
	position: absolute;
	top: .3rem;
	left: .5rem;
}

.btn__promo--30-sleva {
  display: block;
  // position: absolute;
  // top: .3rem;
  // left: .5rem;
  width: 46px;
  height: 40px;
  border: 0;
  background: transparent url("/img/layout/list_promo_slevaminus30.png") center
    center no-repeat;
  background-size: cover;
  z-index: 30;
}

.btn__promo--leto-nadrz {
  display: block;
  position: absolute;
  top: 0.3rem;
  left: 0.5rem;
  width: 50px;
  height: 40px;
  border: 0;
  background: transparent url("/img/layout/list__promo--nadrz-zdarma.png")
    center center no-repeat;
  background-size: cover;
  z-index: 30;
}

.btn__promo--leto-css {
  display: block;
  position: absolute;
  top: 0.3rem;
  left: 0.5rem;
  width: 50px;
  height: 40px;
  border: 0;
  background: transparent url("/img/layout/list__promo--leto-css.png") center
    center no-repeat;
  background-size: cover;
  z-index: 30;
}

.btn__promo--snizena-cena {
  display: block;
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  width: 75px;
  height: 40px;
  border: 0;
  background: transparent url("/img/layout/list__promo--snizena-cena.png")
    center center no-repeat;
  background-size: cover;
  z-index: 30;
}

.btn__promo--slavie-derby {
  display: block;
  position: absolute;
  top: 0.1rem;
  left: 0.1rem;
  width: 75px;
  height: 40px;
  border: 0;
  background: transparent url("/img/layout/list__promo--slavie-derby.png")
    center center no-repeat;
  background-size: cover;
  z-index: 30;
}

.btn__operative_leasing {
  display: block;
  // position: absolute;
  // top: .5rem;
  // left: .5rem;
  width: 40px;
  height: 40px;
  border: 0;
  /* background: transparent url("/img/layout/list__operative_leasing.png") center center no-repeat; */
  background: transparent url("/img/layout/list__operative_leasing.svg") center
    center no-repeat;
  background-size: cover;
  z-index: 30;
}

.btn__scale {
  transition: all 0.5s ease;

  &:hover {
    transform: scale(1.1);
  }
}

.btn360--small {
  right: 0.5rem;
  bottom: 2.8rem;
  width: 40px;
  height: 40px;
}

/* kratsi info vpravo nahore */
.car__info__top {
  h1 {
    font-size: 200%;
    color: $blue;
    text-transform: uppercase;
    text-align: center;
  }

  h5 {
    color: $gray;
  }

  .car__info__top__param div.col-3 {
    height: 4rem;
    font-size: 80%;
    font-weight: bolder;
    color: grey;

    div {
      height: 60%;
      &.fuel {
        background: url("/img/layout/ico-car-detail-fuel.png") no-repeat top
          center transparent;
      }
      &.km {
        background: url("/img/layout/ico-car-detail-km.png") no-repeat top
          center transparent;
      }
      &.year {
        background: url("/img/layout/ico-car-detail-calendar.png") no-repeat top
          center transparent;
      }
      &.trans {
        background: url("/img/layout/ico-car-detail-trans.png") no-repeat top
          center transparent;
      }
    }
  }

  .car__info__top__price {
    h2 {
      font-size: 200%;
    }
    p {
      font-size: 80%;
      color: $gray;
    }
  }

  .car__info__top__distance {
    a {
      color: $gray;
      text-decoration: underline;
      text-transform: uppercase;
    }
  }

  .car__info__top__id {
    text-align: right;
    color: $gray;
  }
}

/* HLAVNI info - taby */
.car__info__main {
  .tab-content {
    font-size: 90%;
    p {
      margin-bottom: 0;
    }
    h3 {
      font-size: 150%;
      color: $blue;
    }
    h6 {
      margin-bottom: 0;
      font-weight: bolder;
    }
  }

  .dekra__logo {
    background: url("/img/layout/ico-detail-dekra-download.png") no-repeat right
      center transparent;
    padding-right: 30px;
    display: inline-block;
    padding-top: 5px;
  }
}

.contact-form {
  h4 {
    color: $blue;
    font-size: 150%;
  }

  .contact-form__ico {
    display: inline-block;
    background: url("/img/layout/ico_clock.png") no-repeat center center
      transparent;
    width: 10%;
    height: 50px;
  }

  .contact-form__headline {
    display: inline-block;
    width: 85%;
    font-size: 80%;
    text-align: left;
    padding-left: 1rem;
  }

  .btn-primary {
    width: 100%;
    background: $blue;
  }

  #contact-form {
    .form-control,
    .help-block {
      font-size: 90%;
    }
    .form-control ul {
      margin-bottom: 0;
    }
  }
}

#contact-modal {
  font-size: 12px;
  hr {
    margin: 20px 50px;
    border-top-color: #aaa;
  }
  .help-block {
    margin: 5px;
  }
}
.contact-modal__box {
  position: relative;
  margin: 20px;
  border: 1px solid #ddd;
}
.contact-modal__header {
  margin: 20px auto;
  max-width: 350px;
  min-height: 120px;
  padding-left: 80px;
  background: transparent url("../img/layout/contact-modal__phone-icon.png")
    left top no-repeat;
}
.contact-modal__title {
  color: $blue;
  font-size: 25px;
  font-weight: bold;
}
.contact-modal__anotation {
  color: $gray;
  font-size: 20px;
  line-height: 1.2em;
}
.contact-modal__input-phone {
  max-width: 250px;
  margin: auto;
  input.form-control {
    border-radius: 0 3px 3px 0 !important;
  }
}
#contact-modal__btn-submit {
  margin-bottom: 20px;
  padding: 20px;
  min-width: 250px;
}
#contact-modal__btn-close {
  position: absolute;
  right: 5px;
  top: 10px;
  background-color: transparent;
  border: none;
}

.ctas {
  padding: 1% 1%;

  a {
    display: block;
    margin: 2% 0;
    height: 4rem;

    &.pdf {
      background: url("/img/layout/ico-ctas-pdf.png") no-repeat center 50% $gray;
    }

    &.print {
      background: url("/img/layout/ico-ctas-print.png") no-repeat center 50%
        $gray;
    }

    &.sendmail {
      background: url("/img/layout/ico-ctas-sendmail.png") no-repeat center 50%
        $gray;
    }
  }
}

.pvv-tabs {
  h5 {
    font-size: 1rem;
    margin-bottom: 0;
  }
  .card-body p {
    font-size: 0.85rem;
    padding: 0.7rem;
  }
}

.logo-program {
  width: 172px;
  padding-top: 15px !important;
  padding-bottom: 15px;
}

.logo-operative-leasing {
  width: 2rem;
  height: 2rem;
}

.operative-leasing-b {
  padding: 0;
}

.logo-operative-leasing-2 {
  width: 3rem;
  height: 3.2rem;
  float: right;
  margin-right: 3.5rem;
}

.logo-operative-leasing-3 {
  width: 2.5rem;
  margin-right: 5px;
}

.ol-list-logo-price {
  margin-bottom: 0.8rem;
  color: #0855ad;
}

.ol-list-logo-price-list-item {
  margin-bottom: 0.8rem;
  color: grey;
	.price-amount {
		color: #0855ad;
	}
}

.operative-leasing-block {
	margin-top: 0.4rem;
	margin-left: -4rem;
	height: 40px;
}

.operative-leasing-info {
  float: left;
  display: block;
  margin-bottom: 0rem;
  line-height: 100%;
  padding-bottom: 0;
}

.operative-leasing-price {
  display: block;
  float: left;

  line-height: initial;
}

.operative-leasing-price-2 {
  color: #0855ad !important;
  font-weight: bold;
  font-size: 150% !important;
  padding: 0px 0px;
  line-height: initial;
}

.operative-leasing-button {
	position: relative;
	padding-right: 50px;
	&:before {
		font-size: 1.2rem !important;
	}
}

.operative-leasing-offer-link {
	color: #007bff;
	font-weight: bold;
	&:hover {
		color: #0056b3;
	}
}


// .select2-search__field {
// 	display: none;
// }

// .select2-result__options:first-child {
// 	display: none;
// }

// .select2-container--default .select2-results__option[aria-disabled=true] {
// 	display: none;
// }

// .select2-container--default .select2-selection--single .select2-selection__rendered {
// 	font-size: 90%;
//   	color: #898989;
// }

// .select2-container--default .select2-results__option[aria-selected=true] {
// 	font-size: 90%;
//   	color: #898989;
// }

.advantages-12 {
  padding-left: 43px !important;
  padding-right: 43px !important;

  @media (max-width: 1200px) and (min-width: 992px) {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  @include tablet {
    padding-left: 34px !important;
    padding-right: 34px !important;
  }

  @include mobile {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
}

.advantages {
  & .modal .advantages__icon {
    position: static;
    transform: translate(0%, 0%);
    -webkit-transform: translate(0%, 0%);
    -moz-transform: translate(0%, 0%);
    -o-transform: translate(0%, 0%);
    -ms-transform: translate(0%, 0%);
    text-align: center;
  }

  & .benefits__moreinfo {
    font-size: 12px;
  }

  &__icon {
    &-label {
      position: absolute;
      bottom: -20px;
      color: #0855ad;
      text-align: center;
      width: 100%;
      font-size: 12px;
      left: 0;
    }

    &.icon-cert {
      background: url("/img/layout/certifikat.png");
      background-size: 35px;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 35px;
      top: 25%;
      height: 100%;
    }

    &.icon-protocol {
      background: url("/img/layout/protokol.png");
      background-size: 35px;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 35px;
      top: 25%;
      height: 100%;
    }
  }

  & .modal-header {
    text-align: center;

    & .modal-title {
      width: 100%;
    }
  }

  & .modal-body {
    padding: 30px;

    & .advantages__icon {
      margin-bottom: 30px;
    }

    & ul {
      list-style-type: none;
      padding: 0;
    }

    & .icon-cert {
      height: 40px;
    }

    & .icon-protocol {
      height: 40px;
    }
  }

  &__icon-holder {
    display: block;
    overflow: hidden;
    width: 100%;
    height: 69px;
    margin: 12px 0;
    position: relative;
    margin-bottom: 10px;

    &--png {
      overflow: visible;
      height: 80px;
      & .advantages__tooltip {
        height: 52px;
      }
    }

    @media (max-width: 1200px) and (min-width: 992px) {
      height: 53px;
    }

    @include tablet {
      margin-bottom: 25px;
      margin-top: 2px;
    }

    @include mobile {
      height: 53px;
    }
  }

  &.col {
    @include tablet {
    }
  }

  &__tooltip {
    height: 52px;
    width: 100%;
    position: relative;
  }

  &__icon {
    font-size: 54px;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);

    &:hover {
      text-decoration: none;
    }

    @media (max-width: 1200px) and (min-width: 992px) {
      font-size: 54px;
    }

    @include mobile {
      font-size: 44px;
    }
  }
}
