/*=========================
  Fulltext - blok na HP
 ========================== */ 

.fulltext__header { 
            
            padding: 3.8rem 0;
            position:absolute;
            z-index:2;
            width: 100%;
            top:0;
            pointer-events: noone;
            text-align: left;

            @include tablet {

                padding:3.8rem 80px;
                text-align: left;
                top: 165px;

            }

            @include mobile{

                padding:3.8rem 15px;
                text-align: left;
                top: 175px;
            }


            @media (max-width:500px) {
                //top:152px;//before close - 200317
                top:170px;

            }
            
            h2, h3, h4 { color: #fff; }            
            h2 { 
                font-size: 200%; 
                font-weight: 200;  

          /*vymazat po akci*/      @include tablet {
                
                    font-size:176%;
                    position:relative; 
                    top:-20px;
                    line-height: 0.1;
                    margin-top:25px;
            }  

                @include mobile {
                
                    font-size:150%;
                    margin-top:0px;
                    top:-26px;
                    
                    position: relative;
                    min-width:320px;

                    & strong {
                        display: inline-block;
                        width:100%;

                    }
            }


            @media (max-width:600px) {
                margin-top:50px;

            }


            h3 {
                 @include mobile {
                
                    font-size:20px !important;
            }

            }


            }

            &__title { margin-bottom: 1.5rem;


            

             }

            .fulltext__header__subtitle { 
            
                h4 { 
                    display: inline-block;
                    font-size: 110%;
                    font-weight: 200;
                    /*margin-right: 1rem; - nemuze, pak je blbe | */
                    &::after { content: ' |'; }
                    &:nth-last-of-type(1)::after { content: ''; }


                    @include mobile {
                        font-size:110%;

                    }

                    & strong {
                        font-size:110%;

                    }
                }
                
            }
            
        }

.fulltext__fluid-container { 
    padding:0;
    overflow-x:hidden; /* puvodne class bootstrap container-fluid - aby nepretekal pres okraj body*/  
    position: relative;
    /*min-height: 20rem;*/ /* doplnit pak rozmery pro desktop/ mobile, jinak vytejka do dalsiho containeru */
    @include desktop {   
        max-height: 376px;                    
    }

    .fulltext__content {
    	            
        position: absolute;;
            
            display: block;
            margin: 0 auto;
            z-index: 2;
            
            bottom: 35px;
            left:50%;
            
            transition: 0.3s all ease;
            -webkit-transition: 0.3s all ease;
            -moz-transition: 0.3s all ease;
            -o-ransition: 0.3s all ease;
            -ms-transition: 0.3s all ease;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            -ms-transform: translateX(-50%);


            @include tablet {
                margin-top:25px;
                position: relative;
                left:0;
                bottom: unset;
                bottom: auto; /*starsi browsery*/
                transform: translateX(-0%);
                -webkit-transform: translateX(-00%);
                -moz-transform: translateX(-0%);
                -o-transform: translateX(-0%);
                -ms-transform: translateX(-0%);


            }

            @include mobile {
                margin-top: 25px;
                position: relative;
                left:0;
                bottom: unset;
                bottom: auto; /*starsi browsery*/
                transform: translateX(-0%);
                -webkit-transform: translateX(-00%);
                -moz-transform: translateX(-0%);
                -o-transform: translateX(-0%);
                -ms-transform: translateX(-0%);


            }
           

        .fulltext__search__define { 
            
            margin: 0 0 1rem 0;
            color: #fff;

            a {
                display: block;
                color: #fff;
                background: $gray;
                padding: .6rem;
                line-height:24px;

                &:hover { 
                    /*opacity: 0.9;*/
                    background: $blue;
                    text-decoration: none;
                }
                
                &.active { 
                    background: $blue;
                    &::after { /* sipecka pod */                        
                        border-top: 12px solid $blue;
                    }
                    &:hover {
                        background-color: #0069d9; /* nativ primary-btn z prebuildu */
                    }
                }
                
                &:hover, &.active { 
                    
                    &::after { /* sipecka pod */
                        border-left: 12px solid transparent;
                        border-right: 12px solid transparent;                        
                        bottom: -12px;
                        content: "";
                        height: 0;
                        left: 35px;
                        position: absolute;
                        width: 0;
                        border-top: 12px solid $blue;
                        transition: 0.3s all ease;
                        -webkit-transition: 0.3s all ease;
                        -moz-transition: 0.3s all ease;
                        -o-ransition: 0.3s all ease;
                        -ms-transition: 0.3s all ease;


                    }
                }

                &:hover.active:after {

                border-top: 12px solid #0069d9
                }

            }  
            
        }        

        .fulltext__search__form {             
            

            .fulltext__search__form__select { 
            
                
                @include desktop {   
                    padding: 0; /* reset aby nebyly mezery */                
                    &.first { padding-left: 15px; } /* dodatek kvul pretejkani u accordition a zaroven mobile normal padding */
                }

                @include mobile { padding-top: 10px; }
                

                .select2-container--default .select2-selection--single { 
                    border-radius: 0;                    
                }
                
                input {
                    width: 100%;
                    height: 100%;
                    padding: 4px 8px;                    
                    display: inline-block;
                    border: 1px solid $gray;
                }
                
            }

            .fulltext__search__form__button  {
                
                @include desktop { padding: 0 15px 0 0; }
                @include tablet  { padding-top: 1rem; }
                @include mobile  { padding-top: 1rem; }

                input { 
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: $blue;
                    background: url("/img/layout/ico-search-input--hp-search.png") no-repeat 10% center $blue;
                    color: #fff; 
                    line-hight: 100%;
                    &:hover {
                        background-color: #0069d9; /* nativ primary-btn z prebuildu */
                        cursor: pointer;
                    }
                }

                /*a {
                    display: block;                
                    background: $blue;
                    padding: 5px 0px;
                    margin: 0;                               

                    @include mobile { padding: 1rem; }

                    &:hover { font-weight: bold; color: #fff; }
                }*/
                
            }
            
        }


        .fulltext__search__define--full-search { 
            
            //padding: 2rem .5rem 1.5rem 0;
            a {
                color: #fff;
                font-size: 90%;
                font-weight: bolder;
                text-decoration: underline;
                &::after { content: " >"; }
                &:hover { text-decoration: none; }
                /*&.active { text-decoration: none; }*/
                &:focus, &:active:focus { color: #fff; }
            }
        }

    }

}


.tablet-mobile-black, .tablet-mobile-black a {
    
        @include tablet {

            color: black !important;

        }

        @include mobile {

            color: black !important;

        }

}


.fulltext__header .new-claim {
    margin-top:10px;
    font-size:110%;
    font-weight: 600;

    & span:first-child {
        color:#0855ad;

    }

}

.new-claim-zaruka {
    float:right;
    display: block;
    position: relative;
    top:-20px;
    font-weight:600;

    & .red {
        padding-left:25px;
        color: #d2232a;

    }

    @media (max-width:992px) {

        top:-206px;

    }

    @media (max-width:800px) {

        top:-195px;
        float: none;
        margin-top: 15px;


    }


     @media (max-width:600px) {

        top:-168px;
        float: none;


    }



}

.akce2 {
        display: inline-block;
        background-color: #d2232a;
        padding:10px 20px;
        transform: rotate(-10deg);
        -webkit-transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -ms-transform: rotate(-10deg);
        -o-transform: rotate(-10deg);
        position: relative;
        top: 5px;


}



.fulltext__header h1, .fulltext__header h3, .fulltext__header h2, .fulltext__header h4 {
    @media (max-width:992px) {
        line-height: 0.1;

    }


}




.fulltext__header h3 {
    @media (max-width:680px) {
        font-size:18px;

    }


    @media (max-width:500px) {
        font-size:15px;

    }


}


.fulltext__header .col-lg-12 {
    @media (max-width:680px) {
        padding:0 ;

    }

}


.akce2 {
    
@media (max-width:720px) {
    
        position:absolute;
        top:-35px;

}

}


.fulltext__header .container {
    @include mobile {

    min-width: 100vw !important;;
    }

}



.btn-slider {
    position: absolute;
    right: calc(50vw - 560px);
    top: 230px;
    height: 40px;
    line-height:40px;
    padding: 0px 40px;
    z-index: 999;
    display: none !important;


    @media (max-width:992px){
        top:230px;
        right: 15px;

    }

        @media (max-width:630px){
        top:180px;
        right: 15px;

    }



}