/*=========================
  Car results list
 ========================== */

.results .headline {
	
	.headline__count, .headline__count__title, .headline__count__subtitle { color: $blue; }

	.headline__count { 				
		font-size: 2.6rem;
		font-weight: 600;
		padding-right: .5rem;
	}

	.headline__count__title {
		padding-top: .5rem;
		font-size: 100%;
	}

	.headline__count__subtitle { 
		color: $gray;
		font-size: 75%;
	}
}