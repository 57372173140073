
.u-hidden {
  display: none !important;
}

.fs-14 {
  font-size: 14px;
}

.normal-case {
  text-transform: initial;
}