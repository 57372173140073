/*=========================
  Header
 ========================== */
$height-header-logo : 5rem;
$header-font-color : $blue;

header {

  text-transform: uppercase;
  color: $header-font-color;
  font-weight: bold;

  	/* LOGO */
	.main__logo {
		height: 100%;

		span {
			display: inline-block;
			background: url("/img/layout/logo--top.png") no-repeat bottom center transparent;
			min-height: $height-header-logo;
			min-width: 14rem;


			@media (max-width:1200px) {
				display:block;
				margin: 0 auto;

			}



				@include tablet{
			display:block;
			margin: 0 auto;


		}


		@include mobile {
			display:block;
			margin: 0 auto;


		}


		}



	}

	.lang {
		font-size: 70%;
		background: url("/img/layout/nav-border-right.gif") no-repeat right center;
		&.border-none { background: none; }
		&.current { text-decoration: underline; }
	}

	.nav-line {

		border-top: 1px solid $gray-opacity;
		border-bottom: 1px solid $gray-opacity;
		background-color: $gray-bg;

		.navbar { /* prebiti bootstrapoveho */


			#navbarContent { @include mobile {	margin-top: 0.5rem; } }

			@include desktop {
				padding: 0;
			}

			.nav-link {

				font-size: 90%;
				/*@include desktop { background: url("/img/layout/nav-border-right.gif") no-repeat right center; }*/
				background: url("/img/layout/nav-border-right.gif") no-repeat 99% center;

				@media (max-width: 575px) { border-bottom: 1px solid $gray-opacity; background: none; }

				&.current { text-decoration: underline; }
				&.border-none { background: none; }
				&:hover { color: $blue; }
				&.lang {
					font-size: 70%;
					padding-top: 1.4rem;
					text-decoration: none;
					border-bottom: 0;
					&:hover { text-decoration: underline;  }
				}

				@include desktop { padding: 1rem; }
			}
		}

		.breadcrumb {
			border-radius: 0;
			background: $gray;
			font-size: 70%;
			margin-bottom: 0;
			font-weight: 200;

			.breadcrumb-item {
				color: #fff;
				&.current { text-decoration: underline; }
				i { padding-right: 6px; }
				&::before { color: #fff; }
			}
			a:hover  {
				i { text-decoration: none; }
				color: $blue;
			}
		}

	}



		.slavia__logo {

			    display: block;
			    background: url(/img/layout/slavia-transparent-small3.png) no-repeat top right transparent;
			    background-size: cover;
			    height: 41px;
			    width: 136px;
			    /* float: right; */
			    margin-top: 4px;
			    position: absolute;
   				left: 50%;
    			transform: translateX(-50%);
    			-webkit-transform: translateX(-50%);
    			-moz-transform: translateX(-50%);
    			-o-transform: translateX(-50%);
    			-ms-transform: translateX(-50%);




	}

}

.link-after {



	@media (max-width:850px) {

		display:none;

	}

	&:after {
		content:"";
		display:block;
		background-color: #efefef;
		width:2px;
		height:60px;
		position: absolute;
		right:0px;
		top:0px;




	}

	&.link-after--second:after {
		right:-40px;


		@media (max-width: 1200px) {
			right: -20px;
		}

	}



	@media (max-width:850px) {

		width: 300px;
	    height: 100px;
	    display: block;
	    margin: 5px auto;
	    float: none;



	    &:after {
		content:"";
		display:block;
		background-color: #efefef;
		width:100%;
		height:2px;
		position: absolute;
		left:0;
		top:0px;

	}


	}




}


.logos__claim {
	font-size: 9px;
    display: block;
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 12px;
    height: 0;
    color: #9896a5;
    text-align: center;




    &--slavia {
    	    font-size: 9px;
		    display: block;
		    position: absolute;
		    left: 0px;
		    bottom: -12px;
		    width: max-content;
		    height: 0;
		    color: #9896a5;
		    text-align: center;


	

    }

}


.header-logos {
	
	padding:0px;
	flex-grow: 0;

}

.header-logos a{
		display:block;
		background-size:cover;
		background-position-x:center;

}


.opel__logo {
	background-image: url(/img/layout/logo-opel-vertical.png);
    height: 52px;
    width: 60px;

}


.subaru__logo {
	background-image: url(/img/layout/logo-subaru.png?v=2);
    height: 50px;
    width: 62px;

}
.citroen__logo {
    background-image: url(/img/layout/logo-citroen.png?v=2);
    height: 69px;
    width: 50px;
    background-position-y: -8px;
}
.peugeot__logo {
    background-image: url(/img/layout/logo-peugeot.png?v=3);
    height: 40px;
    width: 50px;
    margin-top: 6px;
    position: relative;
    
}

.fiat__logo {
	background-image: url(/img/layout/logo-fiat.svg);
	width: 50px;
	height: 54px;
	background-position-y: -4px;
}

.fiatpro__logo {
	background-image: url(/img/layout/logo-fiat-pro.svg);
	width: 50px;
	height: 54px;
	background-position-y: -2px;
}

.abarth__logo {
	background-image: url(/img/layout/logo-abarth.svg);
	width: 50px;
	height: 54px;
}

.jeep__logo {
	background-image: url(/img/layout/logo-jeep.svg);
	width: 50px;
	height: 54px;
}

.alfa__logo {
	background-image: url(/img/layout/logo-alfa-romeo.svg);
	width: 50px;
	height: 54px;
}


.ds__logo {
  background-image: url(/img/layout/logo-ds.png?v=2);
  height: 51px;
  width: 62px;
  background-position: 0px 0px !important;

}


.toyota__logo {
    background-image: url(/img/brands/loga/Toyota-top-logos.png?v=2);
    height: 48px;
    width: 55px; 
    /* background-size: 71% !important; */
    background-repeat: no-repeat;
    /* background-position-y: 53%; */
    /* position: relative; */
    /* top: -1px; */

}

.kia__logo {
	background-image: url(/img/brands/loga/Kia.png?v=2);
    height: 50px;
    width: 60px;
    // background-position-y: -9px;
    /* position: relative; */
    /* right: 7px; */;

}
.suzuki__logo {
	background-image: url(/img/brands/loga/Suzuki.png);
    background-position-x: -2px !important;
    height: 48px;
    width: 55px; 
    position: relative;
    /* left: -9px; */

}


header .slavia-header {
    float: right;
    width: 167px;
}


.top-logos-inner {
	@media (max-width:1200px) {
		display: block;
		margin:0 auto;
		width: 835px;

	}


	@media (max-width:992px) {
		max-width: 100%;

	}

}

.container-top-header{
	@media (max-width:992px) {
		max-width: 100%;
		position: relative;
    	/*left: -2%; WHY??? */ 

	}

}

.top-logos-inner {
	@media (max-width:850px) {
	display:none !important;

	}

} 


.logos-header {

  flex:none !important;
  -ms-flex: none !important;
  -webkit-flex:none !important;
  flex: 0 0 auto;

}



.mt-10 {
	margin-top: 10px;	

}


.pl-30 {
	@media (min-width:992px){
	padding-left:30px;
}
}


.pr-30 {
@media (min-width:900px){	
	padding-right:30px;
}
}

.pr-25 {
	@media (min-width:900px){
		padding-right: 25px;
	}
}


.pl-0-992 {
	@media (min-width:900px){
	padding-left:0px;
}
}


.pr-0-992 {
@media (min-width:900px){	
	padding-right:0px;
}
}



.relative-left-15-992 {
	@media (min-width:900px){
	position: relative;
	left: 15px;
}
}

.block1 {
	max-width: 625px !important; 
	min-width: 624px !important;
	margin-left: 50px;
	margin-right: auto;
	height: 69px !important;
	/*margin-right: -145px; */
	margin-right: -250px;

	@media(max-width: 1200px) {
		margin-right: 0px;
		margin-left: 25px;
		
		
	}

	@media(min-width:1024px) and (max-width: 1200px) {
		margin-left: 10px;
	}

	@media(max-width:1000px){
	width:368px;
	margin-left: calc( 50vw - 310px);
	margin-right: calc( 50vw - 310px);
	margin-bottom:25px;

	   @media(max-width:900px) {
	   		left:-17px;
		}


		&:after {
			display:none !important;

		}

	}



	@media (max-width: 600px) {
		max-width: 100% !important;
		min-width: 100% !important;
		width: 100%;
		padding: 0 2px;

		margin-left: 0;
		margin-right: 0;
		margin-bottom: 50px;
		left: 0;
	}

	.row {
		flex-direction: row;
		gap: 2px;
		flex-wrap: nowrap;
		justify-content: flex-end;

		@media (max-width: 900px) {
			flex-wrap: wrap;
			justify-content: center;
			margin: 0;
			
		}


	}

}

.block2 {
	max-width: 285px !important;
	min-width: 284px !important;
	margin-left: auto;
	margin-right: 57px;
	height: 69px !important;

	@media(max-width: 1200px) {
		margin-right: auto;
		margin-left: -20px;
	}

	@media(max-width:1000px){
	width:294px;
	margin-left: calc( 50vw - 147px);
	margin-right: calc( 50vw - 147px);
	margin-bottom:25px;
	position: relative;


	&:after {
		display:none !important;

	}

	}

	@media (max-width: 600px) {
		margin-left: 0;
		margin-right: 0;
		width: 100%;
		max-width: 100% !important;
		min-width: 100% !important;
		margin-top: 20px;
	}

	.row {
		flex-direction: row;
		gap: 8px;
		flex-wrap: nowrap;
		justify-content: center;
		margin-left: 25px;

		@media (max-width: 600px) {
			margin-left: 0;
		}
	}

}

.block3 {
	height: 69px !important;
	max-width: 222px !important;  
	min-width: 221px !important;
	margin-left: auto;
	margin-right: auto;
	
	@media(max-width:1000px){
	width:222px;
	margin-left: calc( 50vw - 111px);
	margin-right: calc( 50vw - 111px);
	margin-bottom:15px;

	&:after {
		display:none !important;

	}

	}

}



.loga-header {
	
@media(max-width:380px){
		display: none;


}

}

.type1 {
 	position:relative;
 	/* left:28px; */

	@media (max-width: 600px) {
		max-height: 50px;
	}
}




.type2 {
 	position:relative;
 	/* left:15px;  */

	 @media (max-width: 600px) {
		max-height: 50px;
	}

}


.block2 .row {
		@media (max-width:1200px){
			position: relative;
			left: -14px;


		}	

		@media (max-width:1000px){
			left: 0px;


		}	

}



.claim-block1 {

		left: 30px;

		@media (max-width: 1200px) {

		}

		@media(max-width: 1000px){
			left:25px;

		}	

		@media(max-width: 900px) {
			left: 10px;
		}

		@media (max-width: 572px) {
			top: 110px;
		}
}



.claim-block2 {

		left: 35px;

		@media (max-width: 1200px) {
			left: 20px;
		}

		@media(max-width: 1000px){
			left:30px;

		}	

		@media(max-width: 900px){
			left: 20px;

		}	

		@media (max-width: 600px) {
			left: 10px;
		}

}