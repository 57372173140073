// Encoding may be important in some occasions
@charset 'UTF-8';

// 0. Config variables - information about project
@import 'config';

// 1. Plugins - do prebuildu - VZDY pri importu davat bez koncovky .css !!!
/*@import "node_modules/bootstrap/dist/css/bootstrap.min";
@import "node_modules/select2/dist/css/select2.min";
@import "node_modules/blueimp-bootstrap-image-gallery/css/bootstrap-image-gallery.min";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";*/


// 2. Configuration and helpers
@import
  'utils/variables',
  'utils/functions',
  'utils/mixins',
  'utils/utilities';

@import
  'plugins/cookiebot',
  'plugins/hoverbuttons',
  'plugins/slick',
  'plugins/slick-theme';

// 3. Base stuff
@import
  'base/fonts',
  'base/icon-fonts',
  'base/base';
  /*
  'base/typography',
  'base/helpers',
  'base/shortcuts-helpers',
  'base/responsive';*/

// 4. Layout-related sections
@import
  'layout/grid',
  'layout/header',
  'layout/form',
  'layout/results-list',
  'layout/detail',
  'layout/footer';

// 5. Components
@import
'components/products',
'components/swiper',
'components/calculator',
'components/fulltext';
/*  'components/animations',
  'components/buttons';
*/

// 6. Page-specific styles
@import
  'pages/hp',
  'pages/news',
  'pages/repurchase/hp',
  'pages/benefits',
  'pages/branches';

/*
// 7. Themes
@import
  'themes/default';

// 8. Last save via shame file
@import
  'utils/shame';
*/
