#calculatorModal {
 & .modal-dialog {
border: 3px solid $blue;
/* max-width:650px;*/
	 max-width:1280px;

   @media (min-width: 1024px) {
     max-width: 830px;
   }

}
color: $grey;

  & .modal-logo {
    margin-bottom: 16px;

    @media (min-width: 1024px) {
      margin-bottom: 0px;
    }
  }

  & .essox-logo {
    width: 150px;

    @media (min-width: 1024px) {
      margin: 0px;
      margin-right: 70px;
    }

    @media (max-width: 1023px) {
      width: 125px; 
      margin: 10px 0;
    }
    
  }

  & .modal-title {
    font-size:26px;

    @media (min-width: 1024px) {
      /* margin-left: -175px; */
    }


    @media (max-width: 560px) {
      font-size: 20px;
    }


     @media (max-width: 410px) {
      font-size: 16px;
    }

  }

  & .modal-header {
    display: flex;
    flex-direction: column;
    background: #0055a0;
    color: white;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    padding: 20px 20px;

    @media (min-width: 1024px) {
      padding: 8px 20px;
    }




    & h5 {
      color: white;
      width:100%;
    }

    & .close {
      color: white;
      position: absolute;
      right: 15px;
      top: 10px;

      @media (min-width: 1024px) {
        top: initial;
        right: 25px;

        font-size: 44px;
      }
    }
  }




  & label {
    text-transform: uppercase;
        color: $blue;
    font-weight: 600;

  }


  .modal-title-with-logo:before {
    content:"";
    background-image: url("/img/layout/efs-white-logo.png");
    display:block;
    width:50px;
    height:50px;
    background-size: cover;
    top:15px;
    left:15px;
    position:absolute;



     @media (max-width: 560px) {

          width:35px;
          height:35px;
          top: 22px;
    }

  }

  .sliders-prices {
    width: 100%;
    flex-direction: column;
    display: flex;
    justify-items: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .requirements {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    padding: 20px 0;

    @media (min-width: 1024px) {
      width: 97%;
      padding: 10px 0;
    }
  }

  .sliders {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 97%;

    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }

  .financing-offer {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 100%;

    @media (min-width: 1024px) {
      width: 97%;
      flex-direction: row;
      flex-wrap: wrap;

      .finance-heading {
        width: 100%;
      }
    }


  }

  .akontace {
    padding: 10px 0;
    width: 100%;

    @media (min-width: 1024px) {
      padding: 0px 0px;
      padding-right: 10px;
    }
  }

  .doba-splaceni {
    padding: 10px 0;
    width: 100%;

    @media (min-width: 1024px) {
      padding: 0px 0px;
      padding-left: 10px;
    }

    .doba-splaceni-info {
      h4 {
        white-space: nowrap;
      }
    }
  }

  .percents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 20px;
  }


  @media (min-width: 1024px) {
    & .modal-header {
      flex-direction: row;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }


}

.top-line {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  @media (min-width: 1024px) {
    width: 97%;
    flex-direction: row;
    justify-content: space-between;
  }

}

.form-horizontal {
  width: 100%;

  @media (min-width: 768px) {
    width: 60%;
  }

  @media (min-width: 1024px) {
    width: 100%;
  }
}


.upper-index {
  position:relative;
  top:-5px;
  left: 3px;

}

.details {
  display: flex;
  flex-direction: column;
  width: 80%;
  font-size: 12px;

  @media (min-width: 1024px) {
    flex-direction: row;
    width: 66%;
    padding-left: 10px;
    justify-content: space-between;

  }
}

.detail-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 75%;

  @media (min-width: 768px) {
    width: 33%;
  }

  @media (min-width: 1024px) {
    width: 97%;
    flex-direction: row;
    justify-content: space-between;

    button {
      width: 33%;
      height: 45px;
      border-radius: 0;
    }
  }

  .btnpdf, .btnprint {
    position: relative;
    text-align: center;
    margin-bottom: 20px;

    @media (min-width: 1024px) {
      margin-bottom: 0px;
    }
  }

  .btnpdf {
    img {
      position: absolute;
      left: 10px;
      bottom: 5px;

    }
  }

  .btnprint {
    img {
      position: absolute;
      left: 10px;
      bottom: 5px;
    }
  }
}

.legal-line {
  font-size: 11px;
  p {
    color: #9896A5;
  }


  @media (min-width: 1024px) {
    padding: 0 !important;
  }
}

.modal-footer {
  @media (min-width: 1024px) {
    padding: 5px 15px;
    p {
      margin-bottom: 0;
    }
  }
}


.calculator {

  &__box {
    width: 100%;

    @media (min-width: 1024px) {
      width: 33%;
   }
  }

  &__info {
    @media (min-width: 1024px) {
      width: 49%;
    }
  }

  &__car-name {
    text-transform: uppercase;
    color: $blue !important;
    font-weight:600;
    text-align: left;
    margin-bottom: 5px;

    @media (max-width:992px) {
      text-align: center;
    }

    @media (min-width: 1024px) {
      margin-bottom: 0px;
    }

  }

  &__desc {
    font-size: 15px;
    text-transform: uppercase;

    @media (max-width:992px) {
      display: block  ;
      text-align: center;

    }
  }

	&__product-name {
		font-size: 15px;
		text-transform: uppercase;

		@media (max-width:992px) {
			display: block  ;
			text-align: center;

		}
	}

  &__bottom-border {
    margin-top:20px;
    border-bottom: 1px solid $grey;

  }

  &__button {
    color: $grey;
    float: right;
    width: 220px;
    border: 1px solid $grey;
    text-align: center;
    height:35px;
    line-height:35px;
    margin-top: 10px;


    @media (max-width:992px) {
      float: none;
      display:block;
      margin: 10px auto;

    }

    &:hover {
      color: $grey !important;;

    }
  }

&__heading {

      text-transform: uppercase;
      font-weight: 600;

          @media (max-width:992px) {
      display: block  ;
      text-align: center;
    }


  }

    &__heading-slider {
      color: $blue;
      font-weight: 600;
      font-size: 18px;
      margin-bottom:20px;
      margin-top:8px;

      & span {
        font-size: 130%;
		  text-transform: none;
      }


      @media (max-width:992px) {
        display: block  ;
        text-align: center;
      }

      @media (min-width: 1024px) {
        margin-bottom: 5px;
      }
    }

    &__value {
      font-weight:600;

      & span {
        font-size:110%;
      }
    }

    &__box-inner {
       padding: 20px 5px;
       background-color: #eee;
       text-align: center;
       margin: 20px 0;
       display: flex;
       width: 100%;
       flex-direction: column;
       align-items: center;

       @media (min-width: 1024px) {
         margin: 5px 0;
       }
    }


    &__price-text {
          text-transform: uppercase;
          color: $blue;
          font-weight: 600;

       }


    &__price {
      font-size: 2.00rem;
      font-weight: 800;
      color: $blue;
      position: relative;
      top: 2px;

    }


    &__info div {
      font-weight: 280;


    }

         &__slider {
            -webkit-appearance: none;
            width: 100%;
            height: 5px;
            background: #d3d3d3;
            outline: none;
            opacity: 1;
            -webkit-transition: .2s;
            transition: opacity .2s;
          }

          &__slider:hover {
            opacity: 1;
          }

          &__slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 20px;
            height: 20px;
            background: $blue;
            cursor: pointer;
            border-radius:50%;
          }

          &__slider::-moz-range-thumb {
            width: 20px;
            height: 20px;
            background: $blue;
            cursor: pointer;
            border-radius:50%;
          }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {


   .calculator__slider {
            -webkit-appearance: none;
            width: 100%;
            height: auto !important;
            background: transparent;
            outline: none;
            opacity: 1;
            -webkit-transition: .2s;
            transition: opacity .2s;
          }
}






