/*=========================
  Homepage
 ========================== */

.hp__brands {
  
  ul {     
    @include desktop { padding-left: 30%; }
    @include tablet { padding-left: 32%; }
    @include mobile { padding-left: 23%; }    
  }

  .logo-wrapper {
    width: 70px;
    height: 70px;
    margin: 0 auto;
    display: block;
    margin-bottom: 1.1rem;
  }

  .brands-more {    
    font-size: 100%;
    display: block;
    padding-left: 25px;
    margin: .5rem 0;
    text-decoration: underline;
    background: url("/img/layout/ico-search-more.png") no-repeat scroll left center;    
    &:hover { text-decoration: none; }
  }

  .logos img { padding: 1rem; }

}

.hp__benefits, .car__info__main {
  
  ul.nav-tabs {     
    
    border-bottom: 3px solid $blue;
    /*@include mobile { background: none; border-bottom: 0px; }*/
    
    .nav-item {
      padding-left: 0; 
      padding-right: 0;      

      background: url("/img/layout/nav-border-right.gif") no-repeat right center;
      /*@include mobile { background: none; border-bottom: 1px solid $gray-opacity; }*/
      
      a { 
        border-radius: 0; 
        border-width: 0; 
        color: $gray;
        padding: 1rem;        
        &.active {
          background: $blue;  
          color: #fff;           
        }
        
      }
      
      &:nth-last-of-type(1) { background: none; }

    }

  }

  .panel-title a { /* Dolni HP benefity */
    border-radius: 0; 
    border-width: 0;     
    padding: 1rem;
    text-align: center;    
    background: $blue;  
    color: #fff;               
    font-size: 70%;
  }


}





.hp__benefits {
  
  min-height: 280px;




  & .benefits__icon-holder {
      display: block;
      overflow: visible;
      margin: 0 auto;
      position: relative;
      

        @media (min-width:1600px) {
          font-size: 77px;
          width: 77px;
          height: 77px;
        }

        @media (max-width:692px) {
          font-size:70px;

         } 

        & span{
          transition: 0.3s all ease;
          -moz-transition: 0.3s all ease;
          -webkit-transition: 0.3s all ease;
          -o-transition: 0.3s all ease;
          -ms-transition: 0.3s all ease;
        }

        
  }


  & a .benefits__icon-holder  {
          transition: 0.3s all ease;
          -moz-transition: 0.3s all ease;
          -webkit-transition: 0.3s all ease;
          -o-transition: 0.3s all ease;
          -ms-transition: 0.3s all ease;
        }

  & a:hover .benefits__icon-holder  {
          transform: scale(1.02); 
          -ms-transform: scale(1.02); 
          -moz-transform: scale(1.02); 
          -o-transform: scale(1.02);
          -webkit-transform: scale(1.02);
          opacity: 0.9;
        }

& a h3 {
 
 font-size: 13px;
 color: #9896a5;
 font-weight: bolder;
 text-transform: uppercase;
 margin-top: 25px;
 margin-bottom: 15px;
 text-align: center;


 & a:hover h3 {
      color: #0056b3 !important;

 }

 .benefity-hp-head {
      margin-top:30px;

 }




 @media (max-width:692px) {
    margin-bottom: 60px;
    margin-top: 25px;

  }

  }




  .fulltext__header {
    text-align: left;

  }

  & a {

  @media (max-width:692px) {
          display: block;
    margin-bottom: 70px;

  }


  }

  
  




   & .row {
      //display: block;
      flex-wrap: nowrap;

  }

  & .col {

      @media (max-width:692px) {

         -webkit-box-flex: 0;
        flex: 0 0 100%;
       max-width: 100%;

     } 

}
}


.hp__benefits {  /* jine na HP nez na detailu */

  ul.nav-tabs .nav-item a { font-size: 90%; font-weight: bolder; }

  .panel-body { font-size: 90%; }

}


.hp__benefits a:hover h3 {

    color: #0855ad;
 
}




.hp__offers {

  & .link {
    font-size: 1.2rem;
    color: #007bff;
    transition: color .2s;
    cursor: pointer;
  }

  & .link.active {
    text-decoration: underline;
  }

  & .link:hover {
    color: #0056b3;
  }
}